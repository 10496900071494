import { getCurrentUser } from "../api/auth";
import config from "../app-config";

/**
 * Checks if the current user has access to the given function.
 * 
 * If the user is not logged in, false is returned.
 * 
 * If the function is not defined in the config, false is returned.
 * 
 * If the user has a lower access level than required, false is returned.
 * 
 * If the user has a higher or equal access level than required, true is returned.
 * 
 * @param {String} functionName function name to check access for
 * @returns {Boolean} true if access is granted, false if not or user is not logged in 
 */
export function checkAccessFunktion(functionName) {
  const user = getCurrentUser();
  // If the user is not logged in, return false
  if (user.isOk === false) {
    return false;
  }
  // If the function is not defined in the config, return false
  if (config.accessConfig.requiredAccessFunctions[functionName] === undefined) {
    return false;
  }
  // If the user has a lower access level than required, return false
  if (
    config.accessConfig.requiredAccessFunctions[functionName] <=
    user.data.accesslevel
  ) {
    // If the user has a higher or equal access level than required, return true
    return true;
  }
  return false;
}

/**
 * Checks if the current user has access to the given page.
 * 
 * If the user is not logged in, false is returned.
 * 
 * If the page is not defined in the config, false is returned.
 * 
 * If the user has a lower access level than required, false is returned.
 * 
 * If the user has a higher or equal access level than required, true is returned.
 * 
 * @param {String} pageName page name to check access for
 * @returns {Boolean} true if access is granted, false if not or user is not logged in
 */
export function checkAccessPages(pageName) {
  const user = getCurrentUser();
  // If the user is not logged in, return false
  if (user.isOk === false) {
    return false;
  }
  // If the page is not defined in the config, return false
  if (config.accessConfig.requiredAccessPages[pageName] === undefined) {
    return false;
  }
  // If the user has a lower access level than required, return false
  if (
    config.accessConfig.requiredAccessPages[pageName] <= user.data.accesslevel
  ) {
    // If the user has a higher or equal access level than required, return true
    return true;
  }
  return false;
}

/**
 * Filters the given data by the current users firm.
 * 
 * If the user is not logged in, an empty array is returned.
 * 
 * If the user is from the main company, all data is returned.
 * 
 * @deprecated Data is filtered on the server
 * 
 * @param {{frim: String, }} data Data to filter
 * @returns {{frim: String, }[]} Filtered data
 */
export function filterData(data) {
  const user = getCurrentUser();
  // If the user is not logged in, return an empty array
  if (user.isOk === false) {
    return [];
  }
  // If the user is logged in, filter the data
  // If the user is in the main company, return all data
  // If the user is in a sub company, return only data from the sub company
  return data.filter((value) => {
    return (
      user.data.firm === config.main_company ||
      value.Zugehörigkeit === user.data.firm
    );
  });
}

/**
 * Gets the list of all access levels.
 * 
 * @returns {{id: Number, name: String}[]} List of all access levels
 */
export function getAccessLevelList() {
  let keys = Object.keys(config.accessConfig.accessLevels);
  let values = Object.values(config.accessConfig.accessLevels);
  return keys.map((key, index) => {
    return { id: parseInt(key), name: values[index] };
  });
}

export function getAccessLevelId(accessname) {
  let keys = Object.keys(config.accessConfig.accessLevels);
  let values = Object.values(config.accessConfig.accessLevels);
  let index = values.indexOf(accessname);
  return parseInt(keys[index]);

}
