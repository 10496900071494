import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { getCurrentUser, signIn as sendSignInRequest } from "../api/auth";
import CookieManager from "../utils/cookie-manager";
import {adjustThemeToCompanyColor} from "../utils/theme-manager";

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = getCurrentUser();
      if (result.isOk) {
        setUser(result.data);
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (username, password, rememberMe) => {
    const result = await sendSignInRequest(username, password);

    if (result.isOk) {
      setUser(result.data);
      adjustThemeToCompanyColor(result.data.companyColor);
      if (!rememberMe) {
        sessionStorage.setItem("user", JSON.stringify(result.data));
      } else {
        localStorage.setItem("user", JSON.stringify(result.data));
      }
    }
    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    localStorage.clear();
    sessionStorage.clear();
    CookieManager.deleteCookie("token");
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, loading }}
      {...props}
    />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
