import React, { useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router";
import Toolbar, { Item } from "devextreme-react/toolbar";
import ScrollView from "devextreme-react/scroll-view";

import Header from "../../components/general/header/Header";
import Footer from "../../components/general/footer/Footer";
import { useScreenSize } from "../../utils/media-query";

import "./top-nav-toolbar.scss";
import TopNavigationMenu from "../../components/general/top-navigation-menu/TopNavigationMenu";

export default function TopNavToolbar({ title, children }) {
  const scrollViewRef = useRef(null);
  const navigate = useNavigate();
  const { isXSmall, isLarge } = useScreenSize();
  const [menuStatus, setMenuStatus] = useState(
    isLarge ? MenuStatus.Opened : MenuStatus.Closed
  );

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onNavigationChanged = useCallback(
    ({ itemData, event, node }) => {
      if (menuStatus === MenuStatus.Closed || !itemData.path || node.selected) {
        event.preventDefault();
        return;
      }

      navigate(itemData.path);
      scrollViewRef.current.instance.scrollTo(0);

      if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
        setMenuStatus(MenuStatus.Closed);
        event.stopPropagation();
      }
    },
    [navigate, menuStatus, isLarge]
  );

  return (
    <div className={"top-nav-toolbar"}>
      <Header menuToggleEnabled={isXSmall} toggleMenu={toggleMenu}>
        <Toolbar id={"navigation-header"}>
          <Item location={"before"} cssClass={"header-title"} text={title} />
          <Item cssClass="navigation">
            <TopNavigationMenu
              selectedItemChanged={onNavigationChanged}
              openMenu={temporaryOpenMenu}
            />
          </Item>
        </Toolbar>
      </Header>
      <ScrollView ref={scrollViewRef} className={"layout-body with-footer"}>
        <div className={"content"} id="main-content">
          {React.Children.map(children, (item) => {
            return item.type !== Footer && item;
          })}
        </div>
        <div className={"content-block"}>
          {React.Children.map(children, (item) => {
            return item.type === Footer && item;
          })}
        </div>
      </ScrollView>
    </div>
  );
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
};
