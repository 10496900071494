import CookieManager from "../utils/cookie-manager";
import config from "../app-config";
import { put, getCSRFToken, handleCSRFErrors } from "./base-connection";

/**
 * Log in a user with the given credentials and save the token in a cookie.
 *
 * If the login was successful, the users data is returned.
 *
 * @param {String} username username of the user
 * @param {String} password password of the user
 * @returns {Promise<
 * {isOk: Boolean,
 *  message?: String,
 *  data:
 *   {id: Number,
 *   name: String,
 *   companyName: String,
 *   company: Number,
 *   accesslevel: Number,
 *   accesslevelName: String,
 *   department: String,
 *   token: String,
 * }
 * }
 * }>} Resolves to the JSON response from the server
 */
export async function signIn(username, password) {
  try {
    // Send login request
    let login = async (url, params) => fetch(config.api_url + url, {
      method: "POST",
      mode: "cors", // Cross-Origin Resource Sharing (CORS)
      credentials: "include", // Don't send cookies
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": await getCSRFToken(), // Send the CSRF token in the header to authenticate the request
      },
      body: JSON.stringify({
        name: username,
        password: password,
      }),
    }).then((response) => handleCSRFErrors(response, url, params, login))
    .catch((error) => {
      // If an error occurs, log it and return an Error message
      console.log(error);
      return {
        isOk: false,
        message: "Ein Fehler bei der Verbindung zum Server ist aufgetreten",
        data: {},
      };
    });

    let loginResponse = await login("employee/login", {
      name: username,
      password: password,
    });

    if (loginResponse.status === 401) {
      console.error("Failed to login", loginResponse);
      return {
        isOk: false,
        message: "Benutzername oder Passwort ist falsch",
        data: {},
      };
    }

    let loginResponseJSON = await loginResponse.json();
    loginResponseJSON.username = username;

    // If the login was successful, save the token in a cookie to authenticate future requests
    CookieManager.setCookie(
      "token",
      loginResponseJSON.token,
      config.loginTokenDuration
    );
    delete loginResponseJSON.token;

    // Return the user data
    if (loginResponse.status === 307) {
      console.warn("First Login", loginResponse);
      return {
        isOk: true,
        message: "Benutzer muss Passwort ändern",
        data: loginResponseJSON,
      };
    }
    return {
      isOk: true,
      data: loginResponseJSON,
    };
  } catch (error) {
    // If an unexpected error occurs, log it and return an Error message
    console.error("Unexpected error while loging in", error);
    return {
      isOk: false,
      message: "Ein unerwarteter Fehler ist beim Anmelden aufgetreten",
      data: {},
    };
  }
}

/**
 * Check if the user is logged in.
 *
 * if he has a valid token in his cookies.
 *
 * and the data of the user is saved in the storage.
 *
 * either in the session or local storage.
 *
 * @returns {{isOk: Boolean,
 *  message?: String,
 *   data: {id: Number,
 *   name: String,
 *   username: String,
 *   companyName: String,
 *   company: Number,
 *   accesslevel: Number,
 *   accesslevelName: String,
 *   department: String,
 *   token: String,}
 * }} Returns the data of the user if he is logged in data
 */
export function getCurrentUser() {
  try {
    const storageName = "user";
    // Check if the user has a valid token
    if (CookieManager.getCookie("token") === null) {
      // If not, remove the user data from the storage and return an Error message
      localStorage.removeItem(storageName);
      sessionStorage.removeItem(storageName);
      return {
        isOk: false,
        message: "Benutzer ist nicht angemeldet",
      };
    }
    // Check if the user data is saved in the storage
    if (localStorage.getItem(storageName) === null) {
      // If not in local storage, check session storage
      if (sessionStorage.getItem(storageName) === null) {
        // If not in session storage as well, return an Error message
        localStorage.removeItem(storageName);
        sessionStorage.removeItem(storageName);
        return {
          isOk: false,
          message: "Benutzerdaten konnten nicht geladen werden",
        };
      }
      // If the user data is saved in session storage, return it
      return {
        isOk: true,
        data: JSON.parse(sessionStorage.getItem(storageName)),
        message: "Benutzerdaten aus Session Storage geladen",
      };
    }
    // If the user data is saved in local storage, return it
    return {
      isOk: true,
      data: JSON.parse(localStorage.getItem(storageName)),
      message: "Benutzerdaten aus Local Storage geladen",
    };
  } catch (error) {
    console.error("Unexpected error", error);
    return {
      isOk: false,
      message:
        "Ein unerwarteter Fehler ist beim Laden der Benutzerdaten aufgetreten",
    };
  }
}

/**
 * Set the user password to a new password
 *
 * and notify the user with a mail.
 *
 * @param {String} username  The username of the user
 * @param {String} oldpassword The current password of the user
 * @param {String} newpassword The new password of the user
 * @returns
 */
export async function changePassword(oldpassword, newpassword) {
  try {
    let response = await put("employee/password/change", {
      username: getCurrentUser().data.username,
      password: oldpassword,
      newPassword: newpassword,
    });
    console.log(response);
    let status = response.status;
    let data;
    if (response.headers.get("Content-Type") === "application/json") {
      data = await response.json();
    } else {
      data = await response.text();
    }

    if (status === 400 && data === "Missing data") {
      console.error("Failed to change password", response);
      return {
        isOk: false,
        message: "Passwort entspricht nicht den Anforderungen",
        data: {},
      };
    } else if (status === 400 && data === "passwords are the same") {
      console.error("Failed to change password", response);
      return {
        isOk: false,
        message: "Altes und neues Passwort dürfen nicht gleich sein",
        data: {},
      };
    } else if (status === 401) {
      console.error("Failed to change password", response);
      return {
        isOk: false,
        message: "Altes Passwort ist falsch",
        data: {},
      };
    }

    if (status === 200) {
      return {
        isOk: true,
        message: "Passwort erfolgreich geändert",
        data: {},
      };
    }
  } catch (error) {
    // If an unexpected error occurs, log it and return an Error message
    console.error("Unexpected error while changing password", error);
    return {
      isOk: false,
      message:
        "Ein unerwarteter Fehler ist beim ändern des Passworts aufgetreten",
      data: {},
    };
  }
}

/**
 * Reset the password of a user
 *
 * and send a mail to the user with a new temp password.
 *
 * @param {String} identifyer Either the username or the email of the user
 * @returns {Promise<{isOk: Boolean, message: String}>
 */
export async function resetPassword(identifyer) {
  try {
    let data;
    if (identifyer.includes("@")) {
      data = {
        mail: identifyer,
      };
    } else {
      data = {
        username: identifyer,
      };
    }
    let response = await fetch(config.api_url + "employee/password/reset", {
      method: "POST",
      mode: "cors", // Cross-Origin Resource Sharing (CORS)
      credentials: "include", // Don't send cookies
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": await getCSRFToken(), // Send the CSRF token in the header to authenticate the request
      },
      body: JSON.stringify(data),
    }).catch((error) => {
      // If an error occurs, log it and return an Error message
      console.log(error);
      return {
        isOk: false,
        message: "Ein Fehler bei der Verbindung zum Server ist aufgetreten",
        data: {},
      };
    });

    if (response.status === 400) {
      console.error("Failed to reset password", response);
      return {
        isOk: false,
        message: "Benutzer nicht gefunden",
      };
    }

    return {
      isOk: true,
      message: "Passwort erfolgreich zurückgesetzt",
    };
  } catch (error) {
    // If an unexpected error occurs, log it and return an Error message
    console.error("Unexpected error while reseting password", error);
    return {
      isOk: false,
      message:
        "Ein unerwarteter Fehler ist beim zurücksetzen des Passworts aufgetreten",
    };
  }
}
