import React, { useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";

import { resetPassword } from "../../../api/auth";

import "./ResetPasswordForm.scss";

const notificationText =
  "Ein einmal Passwort wurde dir per Email zugesendet. Bitte überprüfe deinen Posteingang.";

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ identifyer: "", password: "" });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { identifyer: email } = formData.current;
      setLoading(true);

      const result = await resetPassword(email);
      setLoading(false);

      if (result.isOk) {
        navigate("/login");
        notify(notificationText, "success", 2500);
      } else {
        notify(result.message, "error", 2000);
      }
    },
    [navigate]
  );

  return (
    <form className={"reset-password-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"identifyer"}
          editorType={"dxTextBox"}
          editorOptions={identifyerEditorOptions}
        >
          <RequiredRule message="Bitte geben Sie den Benutzernamen oder die verknüpfte Email Adresse an" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={submitButtonAttributes}
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Mein Passwort zurücksetzen"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={"login-link"}>
            Zurück zu <Link to={"/login"}>Login</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const identifyerEditorOptions = {
  stylingMode: "filled",
  placeholder: "Benutzername oder Email",
  mode: "text",
};
const submitButtonAttributes = { class: "submit-button" };
