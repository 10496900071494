import React from "react";
import { Link } from "react-router-dom";
import ScrollView from "devextreme-react/scroll-view";

import config from "../../app-config";

import "./single-card.scss";

export default function SingleCard({ title, description, className, children }) {
  return (
    <ScrollView
      height={"100%"}
      width={"100%"}
      className={"with-footer single-card"}
    >
      <div className={"dx-card " + className}>
        <div className={"header"}>
          <div className={"title"}>{title}</div>
          <div className={"description"}>{description}</div>
        </div>
        {children}
      </div>
      {config.showPrivacyPolicy && (
        <div className={"footer"}>
          <div className={"footer-text"}>
            <Link to="/impressum">Impressum</Link> {""}
            <Link to="/datenschutz">Datenschutz</Link>
          </div>
        </div>
      )}
    </ScrollView>
  );
}
