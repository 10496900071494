import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import LoginForm from "./components/auth-page/login-form/LoginForm";
import ResetPasswordForm from "./components/auth-page/reset-password-form/ResetPasswordForm";
import { SingleCard } from "./layouts";

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <SingleCard title="Anmelden">
            <LoginForm />
          </SingleCard>
        }
      />
      {/* <Route
        path={appRoutes[4].path}
        element={
          <React.Suspense fallback={<Loading />}>{appRoutes[4].element}</React.Suspense>
        }
      />
      <Route
        path={appRoutes[5].path}
        element={
          <React.Suspense fallback={<Loading />}>{appRoutes[5].element}</React.Suspense>
        }
      /> */}
      <Route
        path="/reset-password"
        element={
          <SingleCard
            title="Password zurücksetzen"
            description="Gib bitte deine E-Mail-Adresse oder deinen Benutzernamen ein, um dein Passwort zurückzusetzen. Wir senden dir eine E-Mail mit einem temporären neuen Passwort."
            className="reset-password-card"
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route path="*" element={<Navigate to={"/login"} />}></Route>
    </Routes>
  );
}
