import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Footer from "./components/general/footer/Footer";
import Loading from "./components/general/loading/loading";
import SingleCard from "./layouts/single-card/single-card";
import ChangePasswordForm from "./components/auth-page/change-password-form/ChangePasswordForm";
import TopNavToolbar from "./layouts/top-nav-toolbar/top-nav-toolbar";
import appInfo from "./app-info";
import routes from "./app-routes";
import { getCurrentUser } from "./api/auth";

export default function Content() {
  return (
    <TopNavToolbar title={getCurrentUser().data.companyName}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={
              <React.Suspense fallback={<Loading />}>{element}</React.Suspense>
            }
          />
        ))}
        <Route
          path="/change-password"
          element={
            <SingleCard title="Password ändern" className={"password-change-card"}>
              <ChangePasswordForm />
            </SingleCard>
          }
        />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
      <Footer>
        Copyright © 2011-{new Date().getFullYear()} {appInfo.title} Inc.
        <br />
        All trademarks or registered trademarks are property of their respective
        owners.
      </Footer>
    </TopNavToolbar>
  );
}
