export const navigation = [
  {
    text: "Zeiterfassung",
    icon: "fa-regular fa-clock",
    path: "/home",
  },
  {
    text: "Übersicht",
    icon: "fa-solid fa-list",
    path: "/overview",
  },
  {
    text: "Administration",
    icon: "fa-solid fa-user-tie",
    path: "/admin",
  },
  {
    text: "Urlaubs-Verwaltung",
    icon: "fa-solid fa-plane-departure",
    path: "/vacation",
  },
  {
    text: "Firmen-Verwaltung",
    icon: "fa-solid fa-building",
    path: "/company",
  }
];

