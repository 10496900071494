import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import Form, {
  Item,
  Label,
  SimpleItem,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { Link, useNavigate } from "react-router-dom";

import { useAuth } from "../../../contexts/auth";

import "./LoginForm.scss";

/**
 * Create a login form component
 * and provide the functionality to login
 * With the following fields:
 * - Username
 * - Password
 * - Remember me (checkbox)
 *
 * @returns {JSX.Element} Login form component
 */
export default function LoginForm() {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const formData = useRef({ username: "", password: "", rememberMe: false });
  const navigate = useNavigate();

  // Use the signIn function from the auth context to login the user
  // If the login was successful, the user will be redirected to the dashboard
  // If the login was not successful, the user will be notified
  // This function is called when the user clicks on the login button
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault(); // Prevent the default submit behavior
      const { username, password, rememberMe } = formData.current; // Get the username, password and rememberMe value from the form data
      setLoading(true); // Display a loading indicator

      const result = await signIn(username, password, rememberMe); // Sign in the user with the username, password and rememberMe value
      if (!result.isOk) {
        // If the login was not successful, notification will be shown from signIn function
        notify(result.message, "error", 3000);
        setLoading(false); // Hide the loading indicator
      } else if (result.message === "Benutzer muss Passwort ändern") {
        notify("Sie nutzen ein temporäres Passwort, bitte legen Sie ein neues fest", "success", 3000);
        navigate("/change-password"); // If the user has to change his password, he will be redirected to the change password page
      }
    },
    [signIn, navigate]
  );

  // Set the focus on the username input field when the component is mounted
  useEffect(() => {
    document.getElementsByClassName("dx-texteditor-input")[0].focus();
  }, []);

  const changePasswordMode = useCallback((name) => {
    const editor = formRef.current.instance.getEditor(name);
    editor.option(
      "mode",
      editor.option("mode") === "text" ? "password" : "text"
    );
  }, []);

  const getPasswordOptions = useCallback(
    () => ({
      mode: "password",
      valueChangeEvent: "keyup",
      placeholder: "Passwort",
      buttons: [
        {
          name: "password",
          location: "after",
          options: {
            stylingMode: "text",
            icon: "eyeopen",
            onClick: () => changePasswordMode("password"),
          },
        },
      ],
    }),
    [changePasswordMode]
  );

  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading} ref={formRef}>
        <Item // Username input field
          dataField={"username"}
          editorType={"dxTextBox"}
          editorOptions={usernameEditorOptions}
        >
          <RequiredRule message="Der Benutzername ist ein Pflichtfeld" />
          <Label visible={false} />
        </Item>

        <SimpleItem
          dataField="password"
          editorType="dxTextBox"
          editorOptions={getPasswordOptions()}
        >
          <RequiredRule message="Das Passwort ist ein Pflichtfeld" />
          <Label visible={false} />
        </SimpleItem>
        <Item // Remember me checkbox
          dataField={"rememberMe"}
          editorType={"dxCheckBox"}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item>
        <ButtonItem>
          {/* Login button */}
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} /> // Show a loading indicator if the login is in progress
              ) : (
                "Anmelden" // Button text
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'link'}>
            <Link to={'/reset-password'}>Passwort vergessen?</Link>
          </div>
        </Item>
          
      </Form>
    </form>
  );
}

const usernameEditorOptions = {
  stylingMode: "filled",
  placeholder: "Benutzername",
  mode: "text",
};
const rememberMeEditorOptions = {
  text: "Anmeldedaten speichern",
  elementAttr: { class: "form-text" },
};
