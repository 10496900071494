import React, { useMemo } from "react";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";

import { useAuth } from "../../../../contexts/auth";
import { useNavigate } from "react-router";
import { toggleTheme } from "../../../../utils/theme-manager";

import "./UserPanel.scss";

export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth();
  const navigate= useNavigate();
  const menuItems = useMemo(
    () => [
      {
        text: "Abmelden",
        icon: "fa-solid fa-arrow-right-from-bracket",
        onClick: signOut,
      },
      {
        text: "Passwort ändern",
        icon: "fa-solid fa-asterisk",
        onClick: ()=> {
          navigate("/change-password");
        },
      },
      {
        text: "Dark Mode",
        icon: "fa-solid fa-moon",
        onClick: toggleTheme,
      }
    ],
    [signOut, navigate]
  );
  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          <div className={"user-image"}>
            <i className="fa-solid fa-user" style={{ fontSize: "1.6em" }}></i>
          </div>
        </div>
        <div className={"user-identification"}>
          <div className={"user-name"}>{user.name}</div>
          <div className={"user-subdata"}>
            {user.department && <div className={'user-firm'}>{user.department + ":"}</div>}
            <div className={"user-access"}>{user.accesslevelName}</div>
          </div>
        </div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position
            my={{ x: "center", y: "top" }}
            at={{ x: "center", y: "bottom" }}
          />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
