import React, { useEffect, useRef, useMemo } from "react";
import TreeView from "devextreme-react/tree-view";

import { navigation } from "../../../app-navigation";
import { useNavigation } from "../../../contexts/navigation";
import { useScreenSize } from "../../../utils/media-query";
import { checkAccessPages } from "../../../utils/check-access";

import "./TopNavigationMenu.scss";


export default function TopNavigationMenu(props) {
  const { children, selectedItemChanged,onMenuReady } =
    props;

  const { isLarge } = useScreenSize();
  function normalizePath() {
    return navigation
      .filter((item) => {
        let access = checkAccessPages(item.text);
        return access;
      })
      .map((item) => ({
        ...item,
        expanded: isLarge,
        path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
      }));
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef(null);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      if (treeView) {
        treeView.unselectAll();
      }
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }
  }, [currentPath]);

  return (
    <div
      className={"dx-swatch-additional top-navigation-menu"}
      style={{position: 'relative'}}
    >
      {children}
      <div className={"menu-container"}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={"path"}
          selectionMode={"single"}
          focusStateEnabled={false}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={"100%"}
        />
      </div>
    </div>
  );
}
