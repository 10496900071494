import { lazy } from "react";
//import { HomePage, Nutzermanagment, Servermanagment, Skripteausfuehren } from './pages';
import { withNavigationWatcher } from "./contexts/navigation";

const homePage = lazy(() => import("./pages/home/home"));
const overviewPage = lazy(() => import("./pages/overview/overview"));
const adminPage = lazy(() => import("./pages/admin/admin"));
const companyPage = lazy(() => import("./pages/companys/company"));
const vacationPage = lazy(() => import("./pages/vacation/vacation"));

const routes = [
  {
    path: "/home",
    element: homePage,
  },
  {
    path: "/overview",
    element: overviewPage,
  },
  {
    path: "/admin",
    element: adminPage,
  },
  {
    path: "/vacation",
    element: vacationPage,
  },
  {
    path: "/company",
    element: companyPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
